@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --vh: calc(var(--vhi, 1vh));
}

body {
  background-color: #ffffff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #666666;
}

.height-screen {
  height: 100vh;
  height: calc(100 * var(--vh));
}

.height-screen-mobile {
  height: calc(100 * var(--vh) - 52px);
}

.pt-100 {
  padding-top: 100%;
}

.text-64 {
  font-size: 64px;
}

.pt-5-5,
.py-5-5 {
  padding-top: 22px;
}

.pb-5-5,
.py-5-5 {
  padding-bottom: 22px;
}

.pt-4-5,
.py-4-5 {
  padding-top: 18px;
}

.pb-4-5,
.py-4-5 {
  padding-bottom: 18px;
}

.pl-4-5,
.px-4-5 {
  padding-left: 18px;
}

.pr-4-5,
.px-4-5 {
  padding-right: 18px;
}

.container {
  max-width: 1072px;
}

.max-w-400 {
  max-width: 400px;
}

.max-w-480 {
  max-width: 480px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(19, 22, 26, 0.4) !important;
  backdrop-filter: blur(2px);
}

.focus-svg-write:focus svg path[fill] {
  fill: #ffffff;
}

.btn-count:hover svg path[stroke] {
  stroke: #1a47f0;
}

.synchronized-primary:hover svg path[fill],
.synchronized-primary.text-primary svg path[fill] {
  fill: #1a47f0;
}

.btn-count:focus svg path[stroke] {
  stroke: #ffffff;
}

.fh-svg-primary:hover svg path[fill],
.fh-svg-primary:focus svg path[fill] {
  fill: #1a47f0;
}

.rti--container {
  font-size: 14px;
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  border: 1px solid #d8dde2 !important;
  border-radius: 16px !important;
  padding: 16px !important;
  width: 100%;
}

.rti--input {
  width: 100% !important;
}

.svg-disabled svg path[fill] {
  fill: #d8dde2;
}

.text-ellipsis-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}
.text-ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}
.text-ellipsis-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.slider-syncing-2 .slick-slide.slick-active.slick-current img {
  border: 2px solid #4fcb8f;
  border-radius: 4px;
  overflow: hidden;
  padding: 0;
}

.slider-syncing-2 .slick-slide.slick-active.slick-current .parrent-img-slick {
  padding: 2px;
}

/* style for select */
.css-yk16xz-control,
.css-1pahdxg-control {
  font-size: 14px;
  padding: 5px;
  border-radius: 16px !important;
  outline: none !important;
  box-shadow: none !important;
  border-color: #d8dde2 !important;
  width: 184px !important;
}

.css-g1d714-ValueContainer {
  padding-left: 70px !important;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.css-26l3qy-menu {
  /* font-size: 14px; */
  /* background: #ffffff; */
  box-shadow: 0px 2px 10px rgba(29, 29, 29, 0.12) !important;
  /* border-radius: 8px !important; */
  /* width: 200px !important; */
}

.css-26l3qy-menu::-webkit-scrollbar {
  width: 5px;
  background: #222222 !important;
}

.css-26l3qy-menu::-webkit-scrollbar-track {
  background: #222222 !important;
}

/* Handle */
.css-26l3qy-menu::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.3) !important;
}

.css-4ljt47-MenuList {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

@media only screen and (min-width: 992px) {
  .css-yk16xz-control,
  .css-1pahdxg-control {
    width: 256px !important;
  }
}

.modal-animation.ReactModal__Content {
  height: 0;
  opacity: 0;
  transform: translateY(-100%);
  transition: all 500ms ease-in-out;
}

.modal-animation.ReactModal__Content--after-open {
  transform: translateY(0%);
  opacity: 1;
  height: auto;
}

.ReactModal__Overlay--after-open {
  z-index: 20;
}

/* SET CSS BUTTON HAS SVG */
.btn-icon-success,
.btn-icon-primary,
.btn-icon-danger {
  border: 1px solid #d8dde2;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-icon-primary:focus svg path[fill],
.btn-icon-success:focus svg path[fill],
.btn-icon-danger:focus svg path[fill] {
  fill: #ffffff;
}

.btn-icon-primary:hover,
.btn-icon-primary:focus,
.btn-icon-success:hover,
.btn-icon-success:focus,
.btn-icon-danger:hover,
.btn-icon-danger:focus {
  border: none;
}

.btn-icon-success:hover {
  background-color: rgba(52, 201, 129, 0.1);
}

.btn-icon-success:hover svg path[fill] {
  fill: #34c981;
}

.btn-icon-success:focus {
  background-color: #34c981;
  outline: none;
}

.btn-icon-danger:hover {
  background-color: rgba(231, 53, 29, 0.1);
}

.btn-icon-danger:hover svg path[fill] {
  fill: #e7351d;
}

.btn-icon-danger:focus {
  background-color: #e7351d;
  outline: none;
}

.btn-icon-primary:hover {
  background-color: rgba(26, 71, 240, 0.1);
}

.btn-icon-primary:hover svg path[fill] {
  fill: #1a47f0;
}

.btn-icon-primary:focus svg path[fill] {
  fill: #ffffff;
}

.btn-icon-primary:focus {
  background-color: #1a47f0;
  outline: none;
}

/* END */

.link-sidebar {
  color: #7c828b;
}

.link-sidebar svg path,
.link-sidebar svg circle {
  stroke: #7c828b;
}

.link-sidebar-active svg path,
.link-sidebar-active svg circle,
.link-sidebar-active svg rect,
.link-sidebar:hover svg path,
.link-sidebar:hover svg rect,
.link-sidebar:hover svg circle {
  stroke: #1a47f0;
}

.link-sidebar-active {
  background-color: "rgba(74, 153, 247, 0.1)";
  color: #1a47f0;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text-error,
.tooltip .tooltip-text-copied {
  z-index: 101;
  visibility: hidden;
  background-color: #555;
  bottom: 135%;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltip-text-copied {
  bottom: 180%;
  left: -170%;
}

.tooltip .tooltip-text-error::after,
.tooltip .tooltip-text-copied::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltip-text-error,
.tooltip:hover .tooltip-text-copied {
  visibility: visible;
  opacity: 1;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  cursor: pointer;
}

.border-bottom-login {
  border-bottom: 1px solid #e0e0e0;
}

.card-verify {
  background: rgba(29, 29, 29, 0.6);
  backdrop-filter: blur(15px);
  border-radius: 8px;
}

.scrollbars > div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-user .tippy-content {
  padding: 0px;
}

.menu-user .tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  top: 0;
  color: transparent;
}

.menu-user .tippy-box {
  background-color: #0f1012;
  border-radius: 8px;
}

.menu-bar .tippy-content,
.component-card-menu .tippy-content {
  padding: 0px;
}

.component-card-menu .tippy-box {
  box-shadow: 0px 2px 10px 0px #1d1d1d1f;
  background-color: transparent !important;
  max-width: unset !important;
}

.component-card-menu > div[data-tippy-root] {
  max-width: unset !important;
}

@media screen and (max-width: 992px) {
  .menu-bar-avatar > div[data-tippy-root] {
    width: 120px !important;
    transform: translate3d(24px, -88px, 0px) !important;
  }

  .menu-bar-avatar .tippy-content {
    height: 81px !important;
  }

  .menu-bar-avatar .tippy-box {
    width: 120px !important;
  }
}

.menu-bar > div[data-tippy-root] {
  width: 100vw;
  transform: translate3d(0, 0, 0) !important;
  max-width: unset !important;
  z-index: 99999 !important;
}

.menu-bar .tippy-box {
  max-width: unset !important;
  width: 100vw;
}

.menu-bar .tippy-box[data-animation="shift-left"][data-state="hidden"] {
  opacity: 0;
  transform: translateX(100%);
}

.menu-bar .tippy-content {
  height: calc(100 * var(--vh) - 52px);
}

.menu-bar .tippy-content > div {
  height: 100%;
}

.backdrop-blur-40 {
  backdrop-filter: blur(10px);
}

.border-color-gray-06 {
  border-color: #e0e0e0;
}

.sub-collapse-item:last-child {
  @apply pb-0;
}

input.checkbox-green[type="checkbox"] {
  @apply relative cursor-pointer appearance-none;
}

/* input.checkbox-green[type="checkbox"]:before {
  width: 20px;
  height: 20px;
  content: "";
  @apply absolute block top-0 right-0 rounded-full;
} */

/* input.checkbox-green[type="checkbox"]:checked:before {
  background-color: #fdbe44;
} */

input.checkbox-green[type="checkbox"]:checked:after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  left: 4.5px;
  top: 3.5px;
  @apply absolute border-white block;
}

.Toastify__toast-container--bottom-right {
  width: auto !important;
  max-width: 550px !important;
  min-width: 326px !important;
  z-index: 10100 !important;
  padding: 0 !important;
}

.Toastify__toast-theme--light {
  background: #222222 !important;
}

.Toastify__toast {
  min-height: 44px !important;
  margin-bottom: 0px !important;
  box-shadow: 0px 2px 10px rgba(29, 29, 29, 0.12) !important;
  border-radius: 4px !important;
  padding: 10px 14px !important;
}

.select-auto-input:hover p {
  color: #1d1d1d;
  font-weight: 600;
}

.line-two-text {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.line-one-text {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.project-card .tippy-content {
  padding: 0px;
}

.project-card .tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  top: 0;
  color: transparent;
}

.project-card .tippy-box {
  border-radius: 8px;
  background-color: #fff;
}

/* .rc-anchor-normal-footer {
  visibility: hidden;
} */

.project-card [aria-expanded="true"] {
  border-color: #f7ac1b;
}

.select-text:hover {
  font-weight: 600;
  color: #1d1d1d;
}

.height-no-data {
  display: grid;
  grid-template-rows: 164px calc(100% - 164px);
  /* height: 100%; */
}

.grid-project-component {
  display: grid;
  grid-template-columns: calc(100% - 464px) 464px;
}

@keyframes buttonScroll {
  0% {
    bottom: 96px;
  }

  50% {
    bottom: 110px;
  }

  100% {
    bottom: 96px;
  }
}

.app-bar-build {
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(29, 29, 29, 0.12);
  top: 72px;
  height: calc(100vh - 72px);
  background-color: #fff;
  will-change: width;
  transition: width 0.5s cubic-bezier(0.2, 0, 0, 1) 0s;
}

.footer-app-bar-build {
  background: #ffffff;
  border-top: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(29, 29, 29, 0.12);
  height: 80px;
}

.card-add-version {
  border: 2px solid #f7ac1b;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.card-add-version:hover {
  border: 2px solid #fdbe44;
}

.card-add-version:hover h6 {
  color: #fdbe44;
}

.scroll-history ::-webkit-scrollbar {
  width: 5px;
}

.scroll-history ::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.scroll-history ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.card-open-app-bar {
  height: 40px;
  top: 72px;
  box-shadow: 0px 2px 10px rgba(29, 29, 29, 0.12);
  border-radius: 0px 8px 8px 0px;
  will-change: width;
  transition: width 0.5s cubic-bezier(0.2, 0, 0, 1) 0s;
  background-color: #fff;
}

.border-dashed-pcb {
  border: 2px dashed #666666;
}

.card-pcb {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 8px;
}

.w-select .css-2b097c-container {
  width: 100%;
}

.w-select .css-14jk2my-container {
  width: 100%;
}

.w-h-svg svg {
  width: 100%;
  height: 100%;
}

.card-input-editor {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 6px;
}

.root-draft-editor .DraftEditor-root {
  min-height: 36px;
}

.custom-toolbar-editor .t16lpgj {
  border: none;
  border-top: 1px solid #e0e0e0;
  box-shadow: none;
  height: 50px;
  padding-bottom: 17px;
}

.input-editor-toolbar {
  border-top: none !important;
  border-bottom: 1px solid #e4e4e4 !important;
  border-left: none !important;
  border-right: none !important;
  padding: 0px 12px !important;
  margin: 0 !important;
  background: #f7f7f7 !important;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  min-height: 40px !important;
  display: flex;
  align-items: center;
  /* flex-wrap: nowrap !important; */
  /* overflow: auto; */
}

.input-editor-toolbar .rdw-fontfamily-wrapper {
  margin-bottom: 5px !important;
  margin-top: 5px;
}

.input-editor-toolbar .rdw-fontfamily-dropdown {
  margin: 0px 12px 0px 0px !important;
  height: 24px !important;
  width: 150px !important;
}

.input-editor-toolbar .rdw-fontfamily-placeholder {
  font-size: 14px;
  line-height: 24px;
  color: #7a7a7a;
}

.input-editor-toolbar .rdw-dropdown-wrapper {
  border: none !important;
  background: transparent !important;
}

.input-editor-toolbar .rdw-dropdown-carettoopen {
  border-top: 6px solid #7a7a7a !important;
}
.input-editor-toolbar .rdw-dropdown-carettoclose {
  border-bottom: 6px solid #7a7a7a !important;
}

.input-editor-toolbar .rdw-dropdown-wrapper:hover {
  box-shadow: none !important;
  border: none !important;
}

.input-editor-toolbar .rdw-dropdown-selectedtext {
  padding: 0px !important;
}

.input-editor-toolbar .rdw-fontfamily-optionwrapper {
  width: 150px !important;
}

.input-editor-toolbar .rdw-dropdownoption-default {
  font-size: 14px;
  color: #666666;
  height: 30px;
  padding: 0px 5px 0px 12px;
}

.input-editor-toolbar .rdw-dropdown-optionwrapper {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(29, 29, 29, 0.12);
  border-radius: 8px;
  border: none !important;
  overflow-y: auto !important;
  padding-top: 8px;
  padding-bottom: 8px;
  max-height: 200px;
}

.input-editor-toolbar .rdw-dropdown-optionwrapper:hover {
  border: none !important;
  box-shadow: 0px 2px 10px rgba(29, 29, 29, 0.12) !important;
}

.input-editor-toolbar .rdw-dropdownoption-active {
  background-color: #ffffff !important;
  color: #1d1d1d !important;
  font-weight: 600;
}

.input-editor-toolbar .rdw-fontsize-wrapper {
  margin-bottom: 5px !important;
  margin-top: 5px;
}

.input-editor-toolbar .rdw-fontsize-dropdown {
  margin: 0px 12px 0px 0px !important;
  height: 24px !important;
  color: #7a7a7a;
  width: 45px !important;
}

.rdw-fontsize-placeholder {
  font-size: 16px;
  color: #1d1d1d;
}

.input-editor-toolbar .rdw-fontsize-optionwrapper {
  width: 45px !important;
}
.input-editor-toolbar
  .input-editor-toolbar
  .rdw-colorpicker-wrapper
  .input-editor-toolbar
  .rdw-option-wrapper {
  margin: 0 !important;
}
.editor-bold {
  border: none !important;
  height: 30px !important;
  padding: 0px !important;
  margin: 0px 10px 0px 0px !important;
  min-width: 30px !important;
}

.input-editor-toolbar .rdw-inline-wrapper {
  height: 24px !important;
  padding-left: 12px !important;
  border-left: 1px solid #e4e4e4 !important;
  margin-bottom: 5px;
  margin-top: 5px;
  flex: none;
}

.input-editor-toolbar .rdw-option-active {
  box-shadow: 0px 0px 0px 4px rgba(247, 172, 27, 0.1) !important;
  border: 1px solid #f7ac1b !important;
  border-radius: 4px !important;
}

.input-editor-toolbar .rdw-colorpicker-wrapper {
  margin: 5px 0px !important;
  align-items: center !important;
  height: 24px !important;
}

.input-editor-toolbar .rdw-option-wrapper {
  padding: 0px !important;
  min-width: 20px !important;
  height: 20px !important;
  background-color: transparent !important;
  margin: 0px 12px 0px 0px !important;
}

.input-editor-toolbar .rdw-colorpicker-modal .rdw-colorpicker-modal-header {
  display: none;
}

.input-editor-toolbar .rdw-colorpicker-modal {
  padding: 8px 0px 8px 8px;
  border: none !important;
  box-shadow: 0px 2px 10px rgba(29, 29, 29, 0.12) !important;
  border-radius: 8px !important;
  width: 142px !important;
}

.input-editor-toolbar .rdw-list-wrapper {
  height: 24px !important;
  padding-left: 12px !important;
  border-left: 1px solid #e4e4e4 !important;
  margin: 5px 0px !important;
  flex: none;
}

.input-editor-toolbar .rdw-text-align-wrapper {
  margin: 5px 0px !important;
  height: 24px !important;
  border: none !important;
  flex: none;
}
@media screen and (max-width: 992px) {
  .rdw-editor-toolbar {
    /* display: none !important; */
  }
}

.input-editor-class {
  padding-right: 12px;
  padding-left: 12px;
  margin: 12px 0px;
  overflow-y: inherit !important;
}
.input-editor-class .public-DraftStyleDefault-block {
  margin: 0 !important;
  color: #111111;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 24px; */
}
.input-editor-class .public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 0px !important;
}

.input-editor-wrapper {
  height: 100%;
}

.height-editor .DraftEditor-root {
  height: 90px;
}

.card-editor-file {
  background: #f1f1f1;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0px 8px;
  height: 24px;
  display: grid;
}

.grid-card-editor-file-no-download {
  grid-template-columns: calc(100% - 34px) 34px;
}

.grid-card-editor-file {
  grid-template-columns: calc(100% - 68px) 68px;
}

.card-grid-board-specification {
  display: grid;
  grid-template-columns: 257px calc(100% - 257px);
}

.info-specification .tippy-content {
  padding: 16px;
}

.info-specification .tippy-box {
  background: #fcfcfc;
  box-shadow: 0px 2px 10px rgba(29, 29, 29, 0.12);
  border-radius: 8px;
}

.info-specification .tippy-arrow {
  color: #fcfcfc;
}

.icon-button .tippy-content {
  padding: 16px;
}

.icon-button .tippy-box {
  background: #fcfcfc;
  box-shadow: 0px 2px 10px rgba(29, 29, 29, 0.12);
  border-radius: 8px;
}

.icon-button .tippy-arrow {
  color: #fcfcfc;
}

.border-input {
  border: 1px solid #e4e4e4;
}

.border-input:focus {
  border: 1px solid #f7ac1b;
  box-shadow: 0px 0px 0px 4px rgba(247, 172, 27, 0.1);
}

.border-select {
  border: 1px solid #e4e4e4;
}

.border-top-additional {
  border-top: 1px solid #e0e0e0;
}

.width-svg svg {
  width: 100%;
  height: 100%;
}

.hover-text-select:hover span {
  color: #fdbe44 !important;
}
.hover-text-select:hover {
  background-color: #333333 !important;
}
.custom-phone-input .react-tel-input .form-control {
  border: 1px solid #e4e4e4;
  height: 44px;
  background: #ffffff;
  border-radius: 4px;
  width: 100%;
  padding-left: 62px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #111111;
  font-family: "Inter", sans-serif;
}
.custom-phone-input .react-tel-input :disabled {
  cursor: inherit;
  background: #fafafa !important;
}

.custom-phone-input .react-tel-input .selected-flag {
  width: 50px;
  padding-left: 12px;
  position: relative;
}

.custom-phone-input .react-tel-input .selected-flag::before {
  content: "";
  position: absolute;
  height: 32px;
  width: 1px;
  background-color: #e4e4e4;
  right: 0px;
  top: 6px;
}

.custom-phone-input .react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent;
}

.custom-phone-input .react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent;
}

.custom-phone-input .react-tel-input .flag-dropdown.open {
  background: transparent;
}

.custom-phone-input .react-tel-input .flag-dropdown {
  border: none;
  background-color: transparent;
}

.custom-phone-input .react-tel-input .selected-flag .arrow.up {
  border-bottom: 4px solid #666666;
}

.custom-phone-input .react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent;
}

.custom-phone-input .react-tel-input .form-control:focus {
  border: 1px solid #fdbe44;
  box-shadow: 0px 0px 0px 4px rgba(247, 172, 27, 0.1);
}

.custom-phone-input .react-tel-input .country-list {
  background: #222222;
  border-radius: 4px;
  margin: 0px;
  max-width: max-content;
  top: 50px;
}
.custom-phone-input .react-tel-input .country-list::-webkit-scrollbar-thumb {
  background: rgb(253 190 68 / 54%);
}
.custom-phone-input .react-tel-input .search {
  background-color: #222222 !important;
  padding-left: 12 !important;
  height: 40px;
  display: flex;
  align-items: center;
}
.custom-phone-input .react-tel-input .country-list .search-box {
  border: 1px solid #e4e4e4;
  background: #ffffff;
  border-radius: 18px !important;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #111111;
  font-family: "Inter", sans-serif;
  margin-right: 6px;
}
.custom-phone-input .react-tel-input .country-list .country {
  padding: 0px 12px;
  height: 32px;
  display: flex;
  align-items: center;
}

.custom-phone-input .react-tel-input .country-list .country-name {
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #ffffff;
  font-family: "Inter", sans-serif;
}

.custom-phone-input .react-tel-input .country-list .country .dial-code {
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #7a7a7a;
  font-family: "Inter", sans-serif;
}

.custom-phone-input .react-tel-input .country-list .flag {
  margin-top: 0px;
  margin-right: 8px;
}

.custom-phone-input .react-tel-input .country-list .country:hover {
  background-color: #333333;
}
.custom-phone-input
  .react-tel-input
  .country-list
  .country:hover
  .country-name {
  color: #fdbe44;
}
.custom-phone-input .react-tel-input .country-list .country.highlight {
  background-color: transparent;
  font-weight: 600;
}

.active-icon path {
  stroke: #fdbe44;
}

.disable-icon path {
  stroke: #b0b0b0;
}

.white-icon path {
  stroke: #ffffff;
}

.disable-icon path {
  stroke: #d3cece;
}

.hover-icon:hover path {
  stroke: #fdbe44;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.comment-item:hover .show-more-text {
  background-color: #f2f2f2;
}

.comment-item:hover .show-tick {
  display: block;
}

.show-tick {
  display: hidden;
}

.danger-hover-icon:hover path {
  stroke: #e7351d;
}

.active-menu-left svg path {
  fill: #fdbe44;
  stroke: #fdbe44;
}
.active-menu-left .show-line {
  display: block;
}
.hover-menu-left:hover svg path {
  fill: #fdbe44;
  stroke: #fdbe44;
}
.show-line {
  display: none;
}
.hover-menu-left:hover .show-line {
  display: block;
}
.custom-tippy-menu-left {
  background-color: #222222 !important;
  border-radius: 4px !important;
  box-shadow: 0px 2px 10px rgba(29, 29, 29, 0.12) !important;
}
.custom-tippy-menu-left .tippy-arrow {
  color: #222222 !important;
}
.custom-tippy-menu-left .tippy-content {
  padding: 4px 12px !important;
}
.custom-tippy-menu-left:focus-visible {
  outline: none !important;
}
.hover-button-modal:hover {
  background: #e4e4e4;
  border-radius: 6px;
}

.box-shadow-input:focus {
  box-shadow: 0px 0px 0px 4px rgb(247 172 27 / 10%);
  border-color: #fdbe44;
}
.archived-project .header-card svg path {
  stroke: #c7c7c7;
}
.archived-project .header-card span {
  color: #c7c7c7 !important;
}
.archived-project .status-title-card .name-card {
  color: #c7c7c7 !important;
}
.archived-project .status-project-card {
  background: #c7c7c7 !important;
}
.archived-project .status-project-card p {
  color: #f7f7f7 !important;
}
.archived-project .description-project-card p {
  color: #c7c7c7 !important;
}
.archived-project .time-project-card span {
  color: #c7c7c7 !important;
}
.archived-project .category-project-card {
  background: #e4e4e4 !important;
  /* position: relative; */
}
.archived-project .category-project-card p {
  color: #c7c7c7 !important;
}
.active-project-build {
  border: 1px solid #fdbe44 !important;
  box-shadow: 0px 0px 0px 4px rgba(247, 172, 27, 0.1);
}
.hover-project-card:hover {
  border: 1px solid #fdbe44 !important;
  box-shadow: 0px 0px 0px 4px rgba(247, 172, 27, 0.1);
}
.scrollbar-select::-webkit-scrollbar-thumb {
  background: rgb(253 190 68 / 54%);
}
.archived-project-component .type-component-card {
  background: #e4e4e4 !important;
}

.archived-project-component .text-code {
  color: #c7c7c7 !important;
}
.archived-project-component .font-semibold {
  color: #c7c7c7 !important;
}
.archived-project-component .text-name {
  color: #c7c7c7 !important;
}
.archived-project-component .text-time {
  color: #c7c7c7 !important;
}
.color-icon-active svg path[stroke] {
  stroke: #0a5af5;
}
.color-icon-completed svg path[stroke] {
  stroke: #56a012;
}
.color-icon-abandoned svg path[stroke] {
  stroke: #ea4545;
}
.color-icon-no-status svg path[stroke] {
  stroke: #7a7a7a;
}
.hover-component-detail-in-build:hover {
  border: 1px solid #56a012 !important;
  box-shadow: 0px 0px 0px 4px rgb(86 160 18 / 10%) !important;
}

.hover-icon-download:hover {
  background-color: #c7c7c7;
  border-radius: 50%;
}
.show-icon-eye {
  display: none;
}
.hover-action-show-icon-eye:hover .show-icon-eye {
  display: flex;
  align-items: center;
}
.change-color-icon-download-file svg path[stroke] {
  stroke: #111111;
}
.hover-item-history-tree:hover {
  background: #f1f1f1 !important;
}
.hover-item-history-tree:hover .bg-tree-user {
  background: #f1f1f1 !important;
}
.disabled-icon-history svg path[stroke] {
  stroke: #7a7a7a;
  opacity: 0.3;
}
.disable-icon-add-file svg path[stroke] {
  stroke: #111111;
}
.custom-focus-visible:focus-visible {
  outline: none !important;
}
.custom-text-black:hover {
  color: #111111 !important;
}
.custom-icon-clear-search {
  border-radius: 50%;
}
.custom-icon-clear-search:hover {
  background-color: #f7f7f7;
}
.display-icon-edit-name-component {
  display: none;
}
.hover-show-icon-edit-name-component:hover .display-icon-edit-name-component {
  display: block;
}
.disabled-icon-button-create path[stroke] {
  stroke: #7a7a7a;
  opacity: 0.3;
}
.disabled-icon-button-create-fill path[fill] {
  fill: #7a7a7a;
  opacity: 0.3;
}
.active-input-specification {
  box-shadow: 0px 0px 0px 4px rgba(247, 172, 27, 0.1);
  border: 1px solid #f7ac1b;
}
.selector-input::selection {
  background-color: rgba(10, 90, 245, 0.15);
  color: #111111;
}
.hover-action-icon:hover {
  background-color: #7a7a7a42;
  border-radius: 6px;
}
.hover-action-icon:hover svg path[stroke] {
  stroke: #111111;
}
.text-16 {
  font-size: 16px;
}
.text-12 {
  font-size: 12px;
}
.text-13 {
  font-size: 13px;
}
.text-14 {
  font-size: 14px;
}
.leading-18 {
  line-height: 18px;
}
.leading-22 {
  line-height: 22px;
}
.leading-24 {
  line-height: 24px;
}
.leading-26 {
  line-height: 26px;
}
.color-black-222222 {
  color: #222222;
}
.color-black-111111 {
  color: #111111;
}
.color-gray-7a {
  color: #7a7a7a;
}
.color-blue-OA {
  color: #0a5af5;
}
.hover-select-currency:hover {
  background-color: #f7f7f7 !important;
}
.hover-select-currency:hover p {
  color: #111111 !important;
}
.disabled-button-action-table {
  display: none;
}
.hover-disabled-button-action-table:hover .disabled-button-action-table {
  display: flex;
}
.active-button-action-table {
  background-color: #fdbe44 !important;
  border: 1px solid #ffffff !important;
}
.active-button-action-table svg path[fill] {
  fill: #111111;
}
.hover-select-item-supplier:hover {
  background-color: #f7f7f7;
  border-radius: 6px;
}
.hover-select-item-supplier:hover svg path[stroke] {
  stroke: #111111;
}
.focus-input-table {
  border: 1px solid transparent;
}
.focus-input-table:focus {
  border: 1px solid #fdbe44;
}
.btn-small {
  width: 80px;
  height: 28px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #7a7a7a;
  background: #ffffff !important;
}
.btn-small:focus-visible {
  outline: none !important;
}
.btn-small:hover {
  background: #e4e4e4 !important;
  color: #111111;
}
.btn-small-red {
  width: 80px;
  height: 28px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ea4545;
  background: #ffffff !important;
}
.button-small-red:focus-visible {
  outline: none !important;
}
.btn-small-red:hover {
  background: #e4e4e4 !important;
}
.btn-small-yellow {
  width: 80px;
  height: 28px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #111111;
  background: #fdbe44 !important;
}
.btn-small-yellow:focus-visible {
  outline: none !important;
}
.hover-component-build-item:hover {
  background-color: #efefef !important;
}
.btn-small-black {
  width: 80px;
  height: 28px;
  background: linear-gradient(180deg, #3e3e3e 0%, #111111 100%) !important;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}
.icon-select-in-production path[stroke] {
  stroke: #111111;
}
.hover-text-select-in-production:hover {
  background: #333333 !important;
}
.hover-text-select-in-production:hover p {
  color: #fdbe44 !important;
}
.custom-calendar-conversation .rdrCalendarWrapper {
  width: 100%;
}
.custom-calendar-conversation .rdrMonthAndYearWrapper {
  padding-top: 0px;
  height: 40px;
  margin-bottom: 16px;
}
.custom-calendar-conversation .rdrNextPrevButton {
  height: 40px;
  width: 40px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}
.custom-calendar-conversation .rdrNextPrevButton:hover {
  background: rgba(0, 0, 0, 0.07);
}
.custom-calendar-conversation .rdrNextPrevButton i {
  margin: 0px;
}
.custom-calendar-conversation .rdrMonthPicker {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  width: 119px;
  margin: 0px 8px 0px 0px;
}
.custom-calendar-conversation .rdrYearPicker {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  width: 94px;
  margin: 0px;
}
.custom-calendar-conversation .rdrMonthAndYearPickers select {
  width: 100%;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #111111;
}
.custom-calendar-conversation .rdrMonth {
  width: 100%;
  padding: 0px;
}
.custom-calendar-conversation .rdrMonth .rdrWeekDays {
  height: 40px;
  margin-bottom: 4px;
}
.custom-calendar-conversation .rdrMonth .rdrWeekDay {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #111111;
}
.custom-calendar-conversation .rdrMonth .rdrDays button {
  height: 40px;
  color: transparent !important;
}
.custom-calendar-conversation .rdrMonth .rdrDays button span {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.custom-calendar-conversation .rdrStartEdge {
  height: 40px;
  background: #fdbe44;
  border-radius: 4px !important;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px !important;
  border: 1px solid transparent;
}
.custom-calendar-conversation .rdrEndEdge {
  height: 40px;
  background: #fdbe44;
  border-radius: 4px !important;
  right: 0px;
  top: 0px;
  left: 0px !important;
  bottom: 0px;
  border: 1px solid transparent;
}
.custom-calendar-conversation button .rdrInRange {
  height: 40px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  border-radius: 0px;
}
.custom-calendar-conversation .rdrDayEndOfWeek {
  height: 40px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  border-radius: 0px;
}

.custom-calendar-conversation .rdrDayNumber {
  height: 40px;
  top: 0px;
  bottom: 0px;
}
.custom-calendar-conversation
  .rdrDay:not(.rdrDayPassive)
  .rdrInRange
  ~ .rdrDayNumber
  span {
  color: #fdbe44;
}
.custom-calendar-conversation .rdrDayNumber span {
  color: #7a7a7a;
}
.custom-calendar-conversation .rdrDayPassive .rdrDayNumber span {
  color: #c7c7c7;
  opacity: 0.5;
}
.custom-calendar-conversation .rdrDayStartPreview {
  height: 40px;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-radius: 4px !important;
  border: 1px solid #e4e4e4 !important;
  color: transparent !important;
}

.custom-calendar-conversation .rdrDayEndPreview {
  height: 40px;
  border-radius: 4px !important;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border: 1px solid #e4e4e4 !important;
  color: transparent !important;
}
.custom-calendar-conversation .rdrDayInPreview {
  height: 40px;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-radius: 0px !important;
  background: rgba(253, 190, 68, 0.15);
}
.custom-calendar-conversation .rdrDayNumber span::after {
  background: #fdbe44 !important;
}
.hover-text-code-build:hover {
  color: #0a5af5 !important;
  text-decoration-line: underline;
}
.text-decoration-underline:hover {
  text-decoration-line: underline;
}

.custom-calendar-conversation .rdrSelected {
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background: #fdbe44;
  border-radius: 4px;
  height: 40px;
}

.action-icon-download-conversation path[stroke] {
  stroke: #111111;
}
.action-icon-filter {
  background-color: #fdbe44 !important;
  border-radius: 6px;
}
.action-icon-filter svg path[stroke] {
  stroke: #111111;
}
.show-more-list-build-component:hover {
  background: #e4e4e4 !important;
}
.hover-breadcrumbs-text:hover {
  color: #111111 !important;
}
.custom-color-placeholder input::placeholder {
  color: #111111;
}
.hover-header-breadcrumbs:hover p {
  color: #111111 !important;
}
.hover-header-breadcrumbs:hover svg path[stroke] {
  stroke: #111111;
}
.custom-icon-remove-search {
  background-color: #fce8e7 !important;
  border-radius: 6px;
}
.custom-icon-remove-search svg path[stroke] {
  stroke: #d0221d;
  opacity: 1;
}
.bg-gray-custom {
  background-color: #e4e4e4;
}
.input-auto-fill input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  filter: none;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  background-color: #ffffff !important;
}
.color-a-redirect {
  color: rgb(10, 90, 245);
}
.color-a-redirect:hover {
  text-decoration-line: underline;
}
.icon-add-messager path[fill] {
  fill: #56a012;
}
.icon-warning-messager path[fill] {
  fill: #e87a16;
}
.hover-label-header {
  color: #0a5af5 !important;
  text-decoration: underline;
  cursor: pointer;
}
.tracelium-autocomplete input {
  opacity: 1 !important;
}

.bg-delete-element {
  background-size: cover;
  background-color: #f1f1f1;
}
/* .bg-delete-version {
  background-size: cover;
  color: #c7c7c7 !important;
} */
.bg-delete-version td,
.bg-delete-version td a,
.bg-delete-version td p {
  color: #c7c7c7 !important;
  /* background-color: #c7c7c7 !important; */
}
.bg-table-row-delete-element {
  background-size: cover;
  color: #c7c7c7 !important;
}
.bg-table-row-delete-element td,
.bg-table-row-delete-element td a,
.bg-table-row-delete-element td p {
  color: #c7c7c7 !important;
}
.bg-chip-deleted {
  background-size: cover;
}
.bg-chip-deleted,
.bg-chip-deleted p {
  color: #c7c7c7 !important;
}
.custom-break-words {
  word-break: break-word;
}
.first-item-border-top:first-child {
  border-top-width: 1px;
}
.hide-scroll {
  overflow: auto;
}
.hide-scroll::-webkit-scrollbar {
  display: none;
}

.full-layout .tippy-box[data-animation="visible"][data-state="hidden"] {
  background-color: transparent !important;
}
.full-layout .tippy-box {
  background-color: transparent !important;
  transition-duration: 0.5ms !important;
}
.full-layout .tippy-content {
  transition-duration: 0.5ms !important;
}

.border-left-section + * {
  border-left: 1px solid #333333;
}

/* @media screen and (max-width: 992px) {
  .build-info-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    background: #fff;
    padding: 12px;
    height: 100%;
    border: 1px solid #e4e4e4;
    border-radius: 6px;
  }
} */
@media screen and (max-width: 1120px) {
  .group-flex-col {
    display: flex;
    flex-direction: column !important;
  }
}
@media screen and (max-width: 992px) {
  .max-sm-project-content {
    height: calc(100 * var(--vh) - 52px - 64px);
  }

  .custom-tippy-menu-left {
    display: none;
  }
}
.gmail_chip.gmail_drive_chip {
  padding: 0 !important;
}
.scrollbar-stable {
  scrollbar-gutter: stable;
}
.profile-avatar:hover::after {
  content: "Remove";
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 13px;
}
.text-editor .ql-container.ql-snow {
  border: 0 !important;
}
.text-editor .ql-editor {
  padding-left: 0 !important;
}
.text-editor .ql-toolbar.ql-snow {
  padding-right: 0 !important;
}
/* .ql-editor img {
  max-width: 250px;
  max-height: 250px;
  object-fit: cover;
} */

.unable-padding-l {
  padding-left: 0 !important;
}
.collapse-icon:hover path[fill] {
  fill: #333333;
}

.text-editor .ql-snow.ql-toolbar button {
  padding: 2px 4px !important;
}
.ql-snow .ql-picker {
  color: #7a7a7a !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow
  .ql-toolbar
  .ql-picker-item.ql-selected
  .ql-snow.ql-toolbar
  .ql-expanded
  .ql-picker-label,
.ql-snow .ql-toolbar .ql-expanded .ql-picker-label {
  color: white !important;
  background-color: #7a7a7a;
  border-radius: 6px;
  fill: white !important;
  stroke: white !important;
  border: none !important;
}
.ql-snow.ql-toolbar .ql-picker-label,
.ql-snow .ql-toolbar .ql-picker-label {
  border: none !important;
}

.ql-snow.ql-toolbar button:hover > svg path[stroke],
.ql-snow .ql-toolbar button:hover > svg path[stroke],
.ql-snow.ql-toolbar button:focus > svg path[stroke],
.ql-snow .ql-toolbar button:focus > svg path[stroke],
.ql-snow.ql-toolbar button.ql-active > svg path[stroke],
.ql-snow .ql-toolbar button.ql-active > svg path[stroke],
.ql-snow.ql-toolbar .ql-picker-label:hover > svg path[stroke],
.ql-snow .ql-toolbar .ql-picker-label:hover > svg path[stroke],
.ql-snow.ql-toolbar .ql-picker-label.ql-active > svg path[stroke],
.ql-snow .ql-toolbar .ql-picker-label.ql-active > svg path[stroke],
.ql-snow.ql-toolbar .ql-picker-item:hover > svg path[stroke],
.ql-snow .ql-toolbar .ql-picker-item:hover > svg path[stroke],
.ql-snow.ql-toolbar .ql-picker-item.ql-selected > svg path[stroke],
.ql-snow.ql-toolbar .ql-header .ql-picker .ql-picker-label > svg path[stroke],
.ql-snow
  .ql-toolbar
  .ql-header
  .ql-picker
  .ql-picker-label:hover
  > svg
  path[stroke] {
  stroke: white;
}
/* .ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background-color: #222222;
  border: none;
  color: white;
  border-radius: 4px;
} */

/* heading selection */
.ql-header.ql-picker.ql-expanded .ql-picker-options {
  background-color: #222222;
  border-radius: 4px;
  border: none;
  color: white;
  left: -10px;
  top: -148px !important;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  background-color: "#7a7a7a";
}

.rich-editor .ql-editor {
  min-height: 102px;
  margin-bottom: 40px;
}

.text-editor .ql-toolbar.ql-snow {
  /* min-height: 132px; */
  border: none !important;
  padding: none !important;
}
.text-editor:hover > svg path[stroke] {
  stroke: white;
}
.icon-add-file-green svg path[stroke] {
  stroke: #56a012;
}
.message-item ul, .message-item ol {
    list-style: revert;
    padding: revert;
}
